import React from "react";
import "./Skills.css";
import ScreenHeading from "../Utilities/ScreenHeading/ScreenHeading";

function Skills(props) {
  return (
    <div className="skills-me-container screen-container" id={props.id || ""}>
      <div className="skills-me-parent">
        <ScreenHeading title={"Skills"} subHeading={"Skills"} />
      </div>
      <div className='section skills'>
        <ul className="skills__list">
          <li className="skills__list-item btn btn--plain">HTML</li>
          <li className="skills__list-item btn btn--plain">CSS</li>
          <li className="skills__list-item btn btn--plain">SCSS</li>
          <li className="skills__list-item btn btn--plain">JavaScript</li>
          <li className="skills__list-item btn btn--plain">TypeScript</li>
          <li className="skills__list-item btn btn--plain">React</li>
          <li className="skills__list-item btn btn--plain">Redux</li>
          <li className="skills__list-item btn btn--plain">Nextjs</li>
          <li className="skills__list-item btn btn--plain">Nodejs</li>
          <li className="skills__list-item btn btn--plain">MongoDB</li>
          <li className="skills__list-item btn btn--plain">FireBase</li>
          <li className="skills__list-item btn btn--plain">Git</li>
          <li className="skills__list-item btn btn--plain">Material UI</li>
          <li className="skills__list-item btn btn--plain">Tailwindcss</li>
          <li className="skills__list-item btn btn--plain">AntD</li>
        </ul>
      </div>
    </div>
  );
}

export default Skills;
