import React from "react";
import "./Project.css";
import ScreenHeading from "../Utilities/ScreenHeading/ScreenHeading";
import AuctionJournal from "../Images/img/Screenshot 2024-04-20 at 3.42.02 PM.png";
import NftMarket from "../Images/img/Screenshot 2024-04-20 at 3.54.35 PM.png";
import Linkedin from "../Images/img/Screenshot 2024-04-20 at 3.58.26 PM.png";
import TicTacToe from "../Images/img/Screenshot 2024-04-20 at 4.16.55 PM.png";
import Coffee from "../Images/img/Screenshot 2024-04-20 at 5.02.58 PM.png";

export default function Project(props) {
  return (
    <div className="project-me-container screen-container" id={props.id || ""}>
      <div className="project-me-parent">
        <ScreenHeading title={"Projects"} subHeading={"Projects"} />
      </div>
      <div className="section projects">
        <div className="projects__grid">
          <div className="project">
            <h3>Auction Journal</h3>
            <img
              src={AuctionJournal}
              alt="Auction Journal"
              className="project_img"
            />
            <p className="project__description">
              The Auction Journal has been designed by Auctioneers and Consumers
              to be the best and most robust website for auction listings built
              for search and ease of use.
            </p>
            <ul className="project__stack">
              <li className="project__stack-item">JavaScript</li>
              <li className="project__stack-item">Reactjs</li>
              <li className="project__stack-item">Nodejs</li>
              <li className="project__stack-item">Expressjs</li>
              <li className="project__stack-item">MongoDB</li>
              <li className="project__stack-item">MUI</li>
            </ul>

            {/* <a
              href="https://github.com"
              aria-label="source code"
              className="link link--icon"
            >
              <i aria-hidden="true" className="fab fa-github"></i>
            </a> */}
            <a
              href="https://www.auctionjournal.com/"
              aria-label="live preview"
              className="link link--icon"
            >
              <i aria-hidden="true" className="fas fa-external-link-alt"></i>
            </a>
          </div>

          <div className="project">
            <h3>NFT-world.one</h3>
            <img src={NftMarket} alt="NFT-world.one" className="project_img" />
            <p className="project__description">
              Powered by Blockchain Labs LLC, NFT-world.one is not just another
              marketplace; it's the next frontier in brand-to-customer
              interactions. In a digital landscape inundated with fleeting
              engagements, our platform offers a lasting impression.
            </p>
            <ul className="project__stack">
              <li className="project__stack-item">TypeScript</li>
              <li className="project__stack-item">Nextjs</li>
              <li className="project__stack-item">TailwindCss</li>
              <li className="project__stack-item">MongoDB</li>
              <li className="project__stack-item">Web3</li>
            </ul>

            {/* <a
              href="https://github.com"
              aria-label="source code"
              className="link link--icon"
            >
              <i aria-hidden="true" className="fab fa-github"></i>
            </a> */}
            <a
              href="https://nft-world.one/"
              aria-label="live preview"
              className="link link--icon"
            >
              <i aria-hidden="true" className="fas fa-external-link-alt"></i>
            </a>
          </div>

          <div className="project">
            <h3>LinkedIn replica</h3>
            <img src={Linkedin} alt="Linkedin" className="project_img" />
            <p className="project__description">
              I created a LinkedIn replica using React, Firebase, and MUI,
              allowing user authentication, data posting, and storage. Check it
              out at LinkedClone.
            </p>
            <ul className="project__stack">
              <li className="project__stack-item">TypeScript</li>
              <li className="project__stack-item">React</li>
              <li className="project__stack-item">MUI</li>
              <li className="project__stack-item">Firebase</li>
            </ul>

            {/* <a
              href="https://github.com"
              aria-label="source code"
              className="link link--icon"
            >
              <i aria-hidden="true" className="fab fa-github"></i>
            </a> */}
            <a
              href="https://linkedin-clone-5673a.web.app/"
              aria-label="live preview"
              className="link link--icon"
            >
              <i aria-hidden="true" className="fas fa-external-link-alt"></i>
            </a>
          </div>

          <div className="project">
            <h3>Tic Tac Toe</h3>
            <img src={TicTacToe} alt="Tic Tac Toe" className="project_img" />
            <p className="project__description">
              Tic Tac Toe: A classic game of strategy and wit, where two players
              compete to align their symbols in a row, column, or diagonal to
              claim victory.{" "}
            </p>
            <ul className="project__stack">
              <li className="project__stack-item">JavaScript</li>
              <li className="project__stack-item">React</li>
            </ul>

            <a
              href="https://github.com/ImSandeepyadav/Tic-Tac-Toe-Game"
              aria-label="source code"
              className="link link--icon"
            >
              <i aria-hidden="true" className="fab fa-github"></i>
            </a>
            <a
              href="https://tic-tac-toe-game-plum.vercel.app/"
              aria-label="live preview"
              className="link link--icon"
            >
              <i aria-hidden="true" className="fas fa-external-link-alt"></i>
            </a>
          </div>

          <div className="project">
            <h3>coffe-delivery</h3>
            <img src={Coffee} alt="Coffee" className="project_img" />
            <p className="project__description">
              Find the perfect coffee for any time of the day. With Coffee
              Delivery you can get your coffee wherever you are, at any time
            </p>
            <ul className="project__stack">
              <li className="project__stack-item">TypeScript</li>
              <li className="project__stack-item">React</li>
              <li className="project__stack-item">Vite</li>
              <li className="project__stack-item">Zod</li>
            </ul>

            <a
              href="https://github.com/ImSandeepyadav/Coffee-website"
              aria-label="source code"
              className="link link--icon"
            >
              <i aria-hidden="true" className="fab fa-github"></i>
            </a>
            <a
              href="https://coffee-website-tau.vercel.app/"
              aria-label="live preview"
              className="link link--icon"
            >
              <i aria-hidden="true" className="fas fa-external-link-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
