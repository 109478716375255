import React from "react";
import Typical from "react-typical";
import "./Profile.css";
// import ScrollService from "../../Utilities/ScrollService";

export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="colz-icon">
              {/* <a href="https://www.instagram.com/atsandeep5/">
                    <i className="fa fa-instagram" />
                  </a> */}
              <a href="https://www.linkedin.com/in/sandeepyadavme/">
                <i className="fa fa-linkedin" />
              </a>
              <a href="https://github.com/ImSandeepyadav">
                <i className="fa fa-github" />
              </a>
            </div>
          </div>
          <div className="profile-details-name">
            <span className="primary-text">
              Hello, I'M <span className="highlighted-text">Sandeep Yadav</span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              <h1>
                <Typical
                  loop={Infinity}
                  steps={[
                    "Front-End Developer 💻",
                    1000,
                    "React Developer 💻",
                    1000,
                    "Next Developer 💻",
                    1000,
                    "MERN Stack Developer 💻",
                    1000,
                  ]}
                />
              </h1>
            </span>
            <span className="profile-role-tagline">
              Writing code is my passion. I firmly believe in the transformative
              and enhancing power of programming, <br /> and how it can improve
              the lives of those around the world.
            </span>
          </div>
          <div className="profile-options">
            {/* <button
              className="btn primary-btn"
              onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
            >
              {" "}
              Hire Me{" "}
            </button> */}
            <a
              href="Sandeep_Resume.pdf"
              download="Sandeep_Resume.pdf"
            >
              <button className="btn highlighted-btn"> Get Resume </button>
            </a>
          </div>
        </div>
        {/* <div className="profile-picture">
              <div className="profile-picture-background"></div>
            </div> */}
      </div>
    </div>
  );
}
