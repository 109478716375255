import { TOTAL_SCREENS } from './commanUtilis'
import { Subject } from 'rxjs'

export default class ScrollService{
    static scrollHandler = new ScrollService();

    static currentScreenBroadCaster = new Subject()
    static currentScreenFadeIn = new Subject()

    constructor(){
        window.addEventListener("scroll" , this.checkCurrentScreenUnderViewport);
    }
    scrollToHireMe = () => {
        let contactMeScreen = document.getElementById("ContactMe");
        if (!contactMeScreen) return;
    
        contactMeScreen.scrollIntoView({ behavior: "smooth" });
    }
    scrollToHome = ()=>{
        let homeScreen = document.getElementById("Home")
        if(!homeScreen) return;
        homeScreen.scrollIntoView({behavior: "smooth"})
    }
    isElementInView = (elem, type)=>{
        let rec = elem.getBoundingClientRect();
        let elementTop = rec.top;
        let elementBottom = rec.Bottom;

        let partaillyVisible = elementTop < window.innerHeight && elementBottom >=0;
        let compeletelyVisible = elementTop >=0 && elementBottom <=window.innerHeight;

        switch(type){
            case "partial":
                return partaillyVisible;

            case "complete":
                return compeletelyVisible
                default: 
                return false   
        }
    }

    checkCurrentScreenUnderViewport =(event)=>{
        if(!event || Object.keys(event).length < 1)
        return;
        for(let screen of TOTAL_SCREENS){
            let screenFromDom = document.getElementById(screen.screen_name);
            if(!screenFromDom)
            continue;

            let  fullyVisible = this.isElementInView(screenFromDom, "complete");
            let partaillyVisible = this.isElementInView(screenFromDom, "partial")

            if(fullyVisible || partaillyVisible){
                if(partaillyVisible && !screen.alreadyRendered){
                    ScrollService.currentScreenFadeIn.next({
                        fadeInScreen: screen.screen_name
                    });
                    screen['alreadyRendered'] = true;
                    break;
                }
                if(fullyVisible){
                    ScrollService.currentScreenBroadCaster.next({
                        screenInView: screen.screen_name,
                    });
                    break;
                }

            }
        }
    }
}