/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import ScreenHeading from ".././Utilities/ScreenHeading/ScreenHeading";
import ScrollService from ".././Utilities/ScrollService";
import Animations from ".././Utilities/Animations";
import "./Resume.css";

export default function Resume(props) {
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  /* STATIC RESUME DATA */
  const resumeBullets = [
    { label: "Education", logoSrc: "education.svg" },
    { label: "Work History", logoSrc: "work-history.svg" },
    // { label: "Programming Skills", logoSrc: "programming-skills.svg" },
    // { label: "Projects", logoSrc: "projects.svg" },
    { label: "Interests", logoSrc: "interests.svg" },
  ];

  // const programmingSkillsDetails = [

  //   { skill: "JavaScript", ratingPercentage: 60 },
  //   { skill: "React JS", ratingPercentage: 70 },
  //   { skill: "Express JS", ratingPercentage: 40 },
  //   { skill: "Node JS", ratingPercentage: 40 },
  //   { skill: "Material Ui", ratingPercentage: 60 },
  //   { skill: "HTML", ratingPercentage: 75 },
  //   { skill: "CSS", ratingPercentage: 75 },
  //   { skill: "Bootstrap", ratingPercentage: 80 },
  // ];

  // const projectsDetails = [
  //   {
  //     title: "Personal Portfolio Website",
  //     duration: { fromDate: "2020", toDate: "2021" },
  //     description:
  //       "A Personal Portfolio website to showcase all my details and projects at one place.",
  //     subHeading: "Technologies Used: React JS, Bootsrap",
  //   },
  //   {
  //     title: "Voicy - Raise your voice (Social Media Platform)",
  //     duration: { fromDate: "2020", toDate: "2020" },
  //     description:
  //       "Learned how to build powerful, scalable applications in React",
  //     subHeading:
  //       "Technologies Used: React Js, Nodejs.",
  //   },
  //   {
  //     title: "NootBook - Notes taking application",
  //     duration: { fromDate: "2021", toDate: "2021" },
  //     description:
  //       "Taking notes on the go and providing personalised experience is primarily goal of this web application",
  //     subHeading: "Technologies Used:  ReactJs, Mongo DB, Express Js, Node Js, Bootstrap",
  //   },
  //   {
  //     title: "News Portal",
  //     duration: { fromDate: "2021", toDate: "2021" },
  //     description:
  //       "Want some news in hurry, made a robust application for you",
  //     subHeading:
  //       "Technologies Used: React Js, Bootstrap.",
  //   },

  // ];

  const resumeDetails = [
    /* EDUCATION */
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"HEMVATI NANDAN BAHUGUNA GARHWAL UNIVERSITY"}
        subHeading={"BACHELOR OF TECHNOLOGY IN COMPUTER SCIENCE"}
        fromDate={"2017"}
        toDate={"2021"}
      />
      <ResumeHeading
        heading={"T R R R S V M INTER COLLAGE KASHIPUR (UK)"}
        subHeading={"INTERMEDIATE"}
        fromDate={"2016"}
        toDate={"2017"}
      />
      <ResumeHeading
        heading={"T R R R S V M INTER COLLAGE KASHIPUR (UK)"}
        subHeading={" HIGH SCHOOLE"}
        fromDate={"2014"}
        toDate={"2015"}
      />
    </div>,
    /* WORK EXPERIENCE */
    <div className="resume-screen-container" key="work-experience">
      <div className="experience-container">
        <ResumeHeading
          heading={"ZeroCarbon.one, New Delhi"}
          subHeading={"Front-End developer"}
          fromDate={"Jul 2023"}
          toDate={"Present"}
        />

        <div className="experience-description">
          <span className="resume-description-text">
            - A leading Decarbonization company
            headquartered in United States, dedicated to transforming the carbon
            credit marketplace and driving sustainability through cutting-edge
            technologies.
          </span>
          <br />
        </div>
      </div>
      <div className="experience-container">
        <ResumeHeading
          heading={"Redmonk Studios, Bengaluru"}
          subHeading={"Front-End developer"}
          fromDate={"Aug 2022"}
          toDate={"Jul 2023"}
        />

        <div className="experience-description">
          <span className="resume-description-text">
            - Transforming Ideas into Exceptional Digital Experiences. Your
            creative digital agency for design, development, and server
            maintenance solutions.{" "}
          </span>
          <br />
        </div>
      </div>
      <div className="experience-container">
        <ResumeHeading
          heading={"Ice-Tea Media, Bengaluru"}
          subHeading={"Front-End developer"}
          fromDate={"Jun 2021"}
          toDate={"Aug 2022"}
        />

        <div className="experience-description">
          <span className="resume-description-text">
            - Developed User Interface for a multi-national auction website in
            which I developed the main site and their admin panel.
          </span>
          <br />
        </div>
      </div>
      {/* <div className="experience-container">
          <ResumeHeading
            heading={"Digital Marketing Agency in Delhi - Interactive Bees"}
            subHeading={"Software Development Engineer"}
            fromDate={"Dec 2022"}
            toDate={"Feb 2022"}
          />
          
          <div className="experience-description">
            <span className="resume-description-text">
              - Lead a web development throughout the development process of multiple client project.
            </span>
            <br />
          </div>
        </div> */}
      {/* <div className="experience-container">
          <ResumeHeading
            heading={"Indiflux, New Delhi"}
            subHeading={"Web Development Trainee"}
            fromDate={"2020"}
            toDate={"2020"}
          />
        
          <div className="experience-description">
            <span className="resume-description-text">
              - Got the training on the development of multiple web and mobile application.
            </span>
            <br />
            <span className="resume-description-text">
              - trained as graduate interns in development process.
            </span>
            <br />
            <span className="resume-description-text">
              - Team driven development in Angular and later shifted to React.
            </span>
            <br />
          </div>
        </div> */}
    </div>,

    // /* PROGRAMMING SKILLS */
    // <div
    //   className="resume-screen-container programming-skills-container"
    //   key="programming-skills"
    // >
    //   {programmingSkillsDetails.map((skill, index) => (
    //     <div className="skill-parent" key={index}>
    //       <div className="heading-bullet"></div>
    //       <span>{skill.skill}</span>
    //       <div className="skill-percentage">
    //         <div
    //           style={{ width: skill.ratingPercentage + "%" }}
    //           className="active-percentage-bar"
    //         ></div>
    //       </div>
    //     </div>
    //   ))}
    // </div>,
    /* PROJECTS */
    // <div className="resume-screen-container" key="projects">
    //   {projectsDetails.map((projectsDetails, index) => (
    //     <ResumeHeading
    //       key={index}
    //       heading={projectsDetails.title}
    //       subHeading={projectsDetails.subHeading}
    //       description={projectsDetails.description}
    //       fromDate={projectsDetails.duration.fromDate}
    //       toDate={projectsDetails.duration.toDate}
    //     />
    //   ))}
    // </div>,
    /* Interests */
    <div className="resume-screen-container" key="interests">
      <ResumeHeading
        heading="Teaching"
        description="Apart from being a tech enthusiast and a code writer, i also love to teach people what i know simply because i believe in sharing."
      />
      <ResumeHeading
        heading="Music"
        description="Listening to soothing music is something i can never compromise with, skimming through Spotify's pop songs charts is at times the best stress reliever that i can get my hands on."
      />
      <ResumeHeading
        heading="Competitive Gaming"
        description="I like to challenge my reflexes a lot while competing in cricket games, pushing the rank and having interactive gaming sessions excites me the most."
      />
    </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`.././Images/Resume/${bullet.logoSrc}`)}
          alt="B"
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div className="resume-container screen-container" id={props.id || ""}>
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={"My formal Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
}
