import React from "react";
import "./Contact.css";
import ScreenHeading from "../Utilities/ScreenHeading/ScreenHeading";

export default function Contact(props) {
  return (
    <div className="contact-me-container screen-container" id={props.id || ""}>
      <div className="contact-me-parent">
        <ScreenHeading title={"Contact"} subHeading={"Contact"} />
      </div>
      <div className="section contact">
        <div className="contact__grid">
          <div className="contact">
            <div className="link link--icon">
              <i aria-hidden="true" className="fa fa-phone"></i>
            </div>
            <h3>Phone</h3>
            <p className="contact__description">Let's have a call</p>
            <h4>9368821791</h4>
          </div>

          <div className="contact">
            <div className="link link--icon">
              <i aria-hidden="true" className="fa fa-envelope"></i>
            </div>
            <h3>Email</h3>
            <p className="contact__description">Drop me a line</p>
            <h4>atsandeep05@gmail.com</h4>
          </div>

          {/* <div className="contact">
          <div
              className="link link--icon"
            >
              <i aria-hidden="true" className="fas fa-external-link-alt"></i>
            </div>
            <h3>Location</h3>
            <p className="contact__description">
              New Delhi
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
