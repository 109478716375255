/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import ScreenHeading from "../Utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../Utilities/ScrollService";
import Animations from "../Utilities/Animations";
import "./AboutMe.css";

export default function AboutMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);
  const SCREEN_CONSTSANTS = {
    description:
    "As a frontend developer, I have successfully completed multiple web development projects using Javascript, ReactJS, NextJS, and API integration. I have a passion for creating user-friendly and responsive web applications that meet the needs and expectations of the clients and the end-users. I have a strong background in computer science engineering, having graduated from Hemwati Nandan Bahuguna Garhwal University with a Bachelor of Technology degree.",
    //   "I am a Front-End web developer. Powered by Javascript, React, Nextjs and Coffee, I am passionate about designing and coding beautifully simple user-centric applications.",
    highlights: {
      bullets: [
        "Front-End web development",
        "React developer",
        "Nextjs development",
        "Interactive Front End as per the design",
        "Building REST API",
        "Managing database",
      ],
      heading: "Here are a Few Highlights:",
    },
  };
  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };
  useEffect(() => {
    return () => {
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div className="about-me-container screen-container" id={props.id || ""}>
      <div className="about-me-parent">
        <ScreenHeading title={"AboutMe"} subHeading={"Why Choose Me?"} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <span className="about-me-description">
              {SCREEN_CONSTSANTS.description}
            </span>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            {/* <div className="about-me-options">
                        <button className="btn primary-btn"
                        onClick={()=> ScrollService.scrollHandler.scrollToHireMe()}
                        >
                            Hire Me
                        </button>    
                            <a href="Sandeep-Resume.pdf" download='Sandeep-Resume.pdf'>
                             <button className="btn highlighted-btn">Get Resume</button>
                            </a>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
