import Home from "../Home/Home"
import AboutMe from '../AboutMe/AboutMe'
import Resume from '../Resume/Resume'
import './commanUtils.css'; 
import Skills from "../Skills/Skills";
import Project from "../Project/Project";
import Contact from "../Contact/Contact";

export const TOTAL_SCREENS = [
    {
        screen_name: "Home",
        component: Home,
    },   
    {
        screen_name: "AboutMe",
        component: AboutMe,
    },
    {
        screen_name: "Resume",
        component: Resume,
    },
    {
        screen_name: "Skills",
        component: Skills,
    },
    {
        screen_name: "projects",
        component: Project,
    },
    {
        screen_name: "Contact",
        component: Contact,
    },
]
export const GET_SCREEN_INDEX = (screen_name)=>{
    if(!screen_name) return -1;
    for(let i = 0; i < TOTAL_SCREENS.length; i++) {
        if (TOTAL_SCREENS[i].screen_name === screen_name)return i;
    }
    return -1;
}